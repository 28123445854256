import React from 'react'
import { BrowserRouter as Router,Routes,Route} from 'react-router-dom'
import Footer from './components/Footer'
import Header from "./components/Header"
import Home from "./components/Home"
import Contact from './components/Contact'
import Services from './components/Services.jsx'

import "./styles/App.scss"
import "./styles/header.scss"
import "./styles/Home.scss"
import "./styles/footer.scss"
import "./styles/contact.scss"
import "./styles/mediaquerry.scss"


function App() {
  return (
    <Router>
      <Header/>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/contact" element={<Contact/>} />
        <Route path="/services" element={<Services/>} />
      </Routes>
      <Footer/>
    </Router>
  )
}

export default App
